import React from 'react';
import { LandingConstructor } from '@beef/landing-constructor';
import PropTypes from 'prop-types';

const LandConstructor = ({ data }) => <LandingConstructor {...data} />;

LandConstructor.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    blocks: PropTypes.arrayOf(PropTypes.object),
  }),
};

export { LandConstructor };
